/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./src/styles/main.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const appendScript = (scriptInnerHTML) => {
  const scriptElement = document.createElement("script");
  scriptElement.innerHTML = scriptInnerHTML;
  document.head.appendChild(scriptElement);
};
const loadedScripts = "loadedScripts";

// Callback to append scripts
const appendThirdPartyScripts = () => {
  // Variable set to reference as a check that callback ran
  sessionStorage.setItem(loadedScripts, "true");
};

const appendScriptsOnScrollOptions = { once: true };
export const onInitialClientRender = () => {
  window.addEventListener(
    "scroll",
    appendThirdPartyScripts,
    appendScriptsOnScrollOptions
  );
};

export const onRouteUpdate = ({ prevLocation }) => {
  const checkLoadedScripts = sessionStorage.getItem(loadedScripts);

  // If first navigation update and user didn't scroll, append
  // scripts
  if (prevLocation && !checkLoadedScripts) {
    // Prevent appending scripts on scroll
    window.removeEventListener(
      "scroll",
      appendThirdPartyScripts,
      appendScriptsOnScrollOptions
    );
    appendThirdPartyScripts();
  }
};
// const addScript = url => {
//   const script = document.createElement("script")
//   script.src = url
//   script.async = true
//   document.body.appendChild(script)
// }

// export const onClientEntry = () => {
//   window.onload = () => {
//     addScript("https://efw.360connect.com/v2/widget.js")
//   }
// }
